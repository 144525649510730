import { generateOptions } from '../../lib/utils/forms'
import {
    PATTERN_EMAIL,
    PATTERN_NO_NUMERIC_STRING,
    PATTERN_STRING,
} from '../../lib/utils/forms/patterns'

// REQUEST DEMO FORM

export const requestDemoFormFields = [
    {
        name: 'First Name',
        label: 'First Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Last Name',
        label: 'Last Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Email',
        label: 'Email',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_EMAIL, 30),
    },
    {
        name: 'Company',
        label: 'Company',
        type: 'input' as const,
        validation: generateOptions(true, PATTERN_STRING, 30),
    },
    {
        name: 'Trial Source',
        label: 'Describe your data streaming infrastructure and use case',
        type: 'textarea' as const,
    },
]

export const requestDemoFormPardotHandler = 'https://go.pardot.com/l/877242/2022-01-17/9d8pyr'

export const requestDemoFormTextBlock = [
    {
        pretitle: 'Lenses Demo',
        title: 'Book a guided tour',
        description: `
            <ul>
                <li>Schedule a 20-minute demo</li>
                <li>Receive a  trial key to install limited features of Lenses on your clusters</li>
                <li><a href="/product/pricing" title="buy">Buy</a> to extend. Perfect for large-scale Kafka deployments.</li>
            </ul>`,
    },
]

export const requestDemoFormSubmitButtonLabel = 'Schedule now'
export const requestDemoFormSuccessTitle = 'Schedule a demo, start your free trial'
export const requestDemoFormSuccessDescription =
    'Book a 20 minute call with a Lenses specialist to give you a guided tour of the product and collect your trial key'
export const requestDemoFormSuccessButtonLabel = 'Book a demo'
export const requestDemoFormSuccessButtonLink =
    'https://lenses.chilipiper.com/book/me/sandra-czajkowska1?type=my-meeting'
export const requestDemoFormErrorTitle = 'There has been an error'
export const requestDemoFormErrorDescription =
    'There has been an error when sending your request. Please try again.'
export const requestDemoFormErrorButtonLabel = 'Try again'
export const requestDemoFormErrorButtonLink = '/request-demo'
